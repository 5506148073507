<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button" class="btn btn-info mt-3">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Sim/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="telcom" class="col-sm-2 col-form-label">電信業者</label>
							<div class="col-sm-10">
								<input v-model="model.telcom" type="text" class="form-control" id="telcom" name="telcom"
									required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="simnumber" class="col-sm-2 col-form-label">門號</label>
							<div class="col-sm-10">
								<input v-model="model.simnumber" type="text" class="form-control" id="simnumber"
									name="simnumber" required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="sim" class="col-sm-2 col-form-label">SIM卡ICCID編號</label>
							<div class="col-sm-10">
								<input v-model="model.sim" type="text" class="form-control" id="sim" name="sim"
									required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="monthfee" class="col-sm-2 col-form-label">月租費</label>
							<div class="col-sm-10">
								<input v-model="model.monthfee" type="text" class="form-control" id="monthfee"
									name="monthfee" required />
							</div>
						</div>
						<!-- <div class="form-group row">
							<label htmlFor="status" class="col-sm-2 col-form-label">使用狀態</label>
							<div class="col-sm-10">
								<input v-model="model.status"
									type="text"
									class="form-control"
									id="status"
									name="status"
								required />
							</div>
						</div> -->
						<div class="form-group row">
							<label htmlFor="dt1" class="col-sm-2 col-form-label">開通日期</label>
							<div class="col-sm-10">
								<input v-model="model.dt1" type="date" class="form-control" id="dt1" name="dt1" />
							</div>
						</div>
						<!-- <div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								/>
							</div>
						</div> -->
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<textarea rows="5" v-model="model.note" class="form-control" id="note" name="note">
								</textarea>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue'
import { addSim } from '@/api/Sim.js'
import toastr from 'toastr'

export default {
	name: 'ProjectCreate',
	components: {
		LayoutDiv
	},
	data() {
		return {
			model: {
				telcom: '',
				simnumber: '',
				sim: '',
				monthfee: '',
				status: '',
				dt1: '',
				//housecode: '',
				note: '',

			},
			isSaving: false,
		};
	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.telcom == "") test = this.pickupWarning("電信業者欄位必填");
			if (this.model.simnumber == "") test = this.pickupWarning("手機門號欄位必填");
			if (this.model.sim == "") test = this.pickupWarning("SIM卡ICCID編號必填");
			return test;
		},
		pickupWarning(message) {
			toastr.error(message);
			return false;
		},

		handleSave() {
			if (this.isSaving == true) return;
			this.isSaving = true;

			if (this.checkFields() == false) {
				this.isSaving = false
				return false;
			}

			addSim(this.model).then(rep => {

				this.isSaving = false;
				this.reset();
				return rep;
			})
				.catch(error => {
					this.isSaving = false
					return error
				});
		},
		reset() {
			this.model.telcom = "";
			this.model.simnumber = "";
			this.model.sim = "";
			this.model.monthfee = "";
			this.model.status = "";
			this.model.dt1 = "";
			// this.model.housecode = "";
			this.model.note = "";

		}
	}
};
</script>
